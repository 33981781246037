
@media (max-width: 950px) {
  .div-pesquisa {
   display: none;
  }
  .subTitulo {
    font-size: 1.8em !important;
    width: 100% !important;
    text-align: center !important;
    margin-bottom: 10px;
  }
  .subTitulo.sticky {
    display:none;
  }

  .class-page {
    grid-template-columns: 1fr !important;
  }
  .class-page-agenda {
    grid-template-columns: 1fr !important;
  }
  .ant-menu-vertical .ant-menu-item {
  height: 24px;
  line-height: 1.5;
  font-size: 0.8em !important;
  }
  .ant-menu-submenu-title {
    font-size: 0.9em !important;
    height: 24px;
  }

  .ant-menu-vertical .ant-menu-submenu-title {
    height: 24px;
    line-height: 1;
    font-size: 0.8em !important;
    }
  

  #btn-voltar {
    display:none;
  }
  .div-footer {
    max-width: 100% !important;
  }
}

html {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 18px;
}

header {
  display: contents;
}

body {
  font-family: "Lato", Arial, sans-serif !important;
  margin: 0;
  background-color: #fff;
  font-size: 18px;
}

label {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 18px;
}

.svg-icon {
  font-size: 28px;
  margin-top: 16px;
  color: rgb(117, 117, 117);
  width: 44px;
  height: 44px;
  padding: 8px;

  @media (max-width: 1050px) {
    margin-top: 0px;
  }
}

.svg-icon2 {
  width: 44px;
  height: 44px;
  padding: 8px;
}

p {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 18px !important;
  color: #37474F;
  line-height: 1.7;
  text-align: left !important;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

p a {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 18px !important;
  position: relative;
  text-decoration: none;
  color: #ae2800;
  text-decoration: underline;
}


p img {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  display: block;
  border: none;
  width: 100%;
  height: 70vh;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;
}

/* p a::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ae2800;
  transition: width 0.3s ease;
} 

p a:hover::after {
  width: 100%;
}
*/

.class-page {
display: grid;
grid-template-columns: 3fr 1fr;
grid-column-gap: 20px;
}

.class-page-agenda {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  font-family: "Lato", Arial, sans-serif !important;
}

a {
  font-family: "Lato", Arial, sans-serif !important;
  text-decoration:underline;
  color: #ae2800;
}

ul:before{
  content:attr(aria-label);
  font-size:110%;
  margin-left:-20px;
}

li {
  font-size: 16px;
  line-height: 1.5em;
}

.liindex a {
  font-size: 14px;
  line-height: 1.5em;
  color: #37474F !important;
}

.ant-picker-cell {
  border-top: 2px solid #f2f2f2;
  align-content: flex-start;
}

.ant-picker-calendar-date-value {
  font-size: 16px;
}

.ant-picker-cell {
  color: black !important;
}


.ant-picker-cell-in-view {
  color: #ae2800 !important;
  font-weight: 500 !important;
}


.link-titulo a {
  font-family: "Lato", Arial, sans-serif;
  color: #37474F !important;
}

.link-titulo a:hover {
  color: #ae2800 !important;
}

a:hover {
  color: #ae2800;
}

h1 {
  margin: 0px;
  font-size: 1em;
}

.h1style  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 2em !important;
  color: #ae2800 !important;
  line-height: 1.2;
  text-align: left !important;
  font-weight: 700;
}

h2 {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.7em !important;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.5em;
}

.h2style  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.7em !important;
  color: #37474F !important;
  line-height: 1.5em;
  margin: 10px 0 !important;
}

h3  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.3em;
  color: #37474F;
}

h5  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.2em !important;
  font-weight: normal !important;
  line-height: 0;
  margin: 0px !important;
  color: #37474F !important;
}

h6  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.2em !important;
  font-weight: normal !important;
  margin: 0px !important;
  color: #37474F !important;
}

.ant-breadcrumb-link {
  font-size: 16px;
}

.ant-breadcrumb-link a {
  font-size: 16px;
}

.ant-breadcrumb li {
  color: #252525 !important;
}

.ant-input-search-button {
  height: 44px !important;

}

.titulo-lista  {
  font-family: "Lato", Arial, sans-serif !important;
  font-size: 1.2em !important;
  font-weight: normal !important;
  margin: 0px !important;
  color: #37474F !important;
  text-decoration: none !important;
}

.titulo-lista  a {
  text-decoration: none;
}

.table table {
  width: 100%;
}

.table table, th, td {
/*   border: 1px solid #d9d9d9; */
  border-collapse: collapse;
}

.table table th {
    background: rgba(0,0,0,.05);
    font-weight: 700;
    font-size: 1.05em;
}

.center {
  text-align: center !important;
  align-items: center !important;
}

.center-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.direita {
  text-align: right !important;
  align-items: right !important;

  @media (max-width: 768px) {
    text-align: left !important;
    align-items: left !important;
  }
}

.direito {
  float: right;
}

.esquerda {
  text-align: left !important;
  align-items: left !important;
  margin-right: auto;
}

.italico {
  font-style: italic !important;
}

.button {
  border-radius: 0px;
	background-color: #37474F;
	border: 2px solid #d9d9d9;
	color: whitesmoke;
	padding: 0.5em 1.5em;
	text-align: center;
	text-decoration: none;
	font-size: 1em;
	display: inline-block;
}
.button:hover {
	background-color: #eee;
}
.buttonPrincipal {
  border-radius: 0px;
  border: 1px solid #37474F;
	color: #37474F;
	padding: 0.5em 1.5em;
	text-align: center;
	text-decoration: none;
	font-size: 1.2em;
	display: inline-block;
  margin: 4px;
  font-family: "Lato", Arial, sans-serif;
  cursor: pointer;
  background-color: #fff;
}
.buttonPrincipal:hover {
	background-color: #37474F;
  color: #fff;
}
.buttonSecundario {
  border-radius: 0px;
  border: 1px solid #37474F;
	color: #37474F;
	text-align: center;
	text-decoration: none;
	font-size: 16px !important;
  font-family: "Lato", Arial, sans-serif;
	display: inline-block;
  margin: 4px;
  padding: 10px 30px 10px 30px;
  line-height: 1em;
  cursor: pointer;
}
.buttonSecundario:hover {
	background-color: #37474F;
  color: #fff;
}

.buttonEnviar {
  border-radius: 0px;
  border: 1px solid #37474F;
	background-color: #37474F;
  color: #fff;
	text-align: center;
	text-decoration: none;
	font-size: 16px !important;
  font-family: "Lato", Arial, sans-serif;
	display: inline-block;
  margin: 4px;
  padding: 10px 30px 10px 30px;
  line-height: 1em;
  cursor: pointer;
}
.buttonEnviar:hover {
  color: #37474F;
  background-color: #fff;
}

.buttonEnviarDisabled {
  border-radius: 0px;
  border: 1px solid #37474F;
	background-color: #37474F;
  color: #fff;
	text-align: center;
	text-decoration: none;
	font-size: 16px !important;
  font-family: "Lato", Arial, sans-serif;
	display: inline-block;
  margin: 4px;
  padding: 10px 30px 10px 30px;
  line-height: 1em;
}

.social-icon-share {
  font-size: 24px;
  margin: 0px 6px 20px 6px;
  color: #808080;
}

.social-icon-share:hover {
  color: #000;
}

.buttonSabermais {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 1px solid #37474F;
	color: #fff;
	padding: 0.5em 1em;
	text-align: center;
	text-decoration: none;
	font-size: 1.2em;
	display: inline-block;
  margin: 4px;
  font-family: "Lato", Arial, sans-serif;
}
.buttonSabermais:hover {
	background-color: #fff !important;
  color: #37474F;
  text-shadow: none;
}

.menu-principal {
  font-size: 1em;
  border: 0px;
  position: sticky;
  top: 0;
  z-index: 999;
  padding-left: 5%;
/*   transition: background-color 0.3s ease; */
}

.barra-menu {
  background-color: #fff;
  width: 100%;
  position:sticky;
  top: 0;
  z-index: 999;
}

.menu-principal.sticky {
  background-color:  #fff; /*  #f3f9ec Defina a cor de fundo desejada para o sticky menu */
  /* background: linear-gradient(155deg,#C8E6C9 0%,  #DCEDC8 20%, #C8E6C9 40%, #E8F5E9 100%); */
}

.itemleft { grid-area: left; }
.itemright { grid-area: right; border-radius: 6px; border: 2px solid #fff; background-color: #d9d9d9; padding: 10px; }

.image img {
  max-width: 50vw;
  max-height: 50vh;
  aspect-ratio: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img {
  max-width: 100%;
}
.image-style-side {
  float: right;
  /* margin-left: var(--ck-image-style-spacing); */
  max-width: 50%;
}

figure {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image>figcaption {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
    background-color: #f2f2f2;
    caption-side: bottom;
    color: #333;
    display: table-caption;
    font-size: .75em;
    outline-offset: -1px;
    padding: .6em;
}

.ant-upload {
  width: 100%;
}
.ant-btn-default {
  width: 100%;
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
}
.ant-btn-default {
  border-color: #c0c0c0 !important;
}

.container-cinza {
  width: 100%;
  background-color: #f8f8f8;
}

.container-verde {
  width: 100%;
  background-color: #E8F5E9;
}

.container-cinzaescuro {
  position: sticky;
  position: -webkit-sticky; /* Safari */  
  width: 100%;
  background-color: #e6e6e6;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container {
  width: 90%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.areabox {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.areaboxchildren {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  flex-grow: 1;
  min-width: 140px;
  max-width: 33%;
  color: #fff;
  opacity: 0;
  align-items: center;
  text-align: center;
  transition: opacity 0.5s, visibility 0s linear 0.5s;

  @media (max-width: 768px) {
    min-width: 100%;
  }
}

.areaboxchildren:link{
  text-decoration: none;
}

.areaboxchildren1 {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  flex-grow: 1;
  min-width: 31%;
  height: 200px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s, visibility 0s linear 0.5s;
}


.areaboxchildren1:link{
  text-decoration: none;
}

.areaboxchildren2 {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  flex-grow: 1;
  min-width: 31%;
  height: 200px;
  color: #fff;
}


.areaboxchildren2:link{
  text-decoration: none;
}

.areaboxtitulo {
  color: #ae2800;
  font-size: 1.7em;
  text-align: center;
  line-height: 1.5em;
  font-weight: 700;
  white-space: normal;
  overflow: hidden;
}

.areaboxtitulop {
  color: #ae2800;
  font-size: 1.5em;
  text-align: center;
  line-height: 1.5em;
  font-weight: 700;
  white-space: normal;
  overflow: hidden;
}


.ant-menu-horizontal {
  border-bottom: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ant-menu-item a {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  color: #37474F !important;
}
.ant-menu-horizontal .ant-menu-item {
  margin-inline-end: 24px;
  padding-inline: 4px !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #0e6ff7;
}

.ant-picker-calendar .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
 background: #0e6ff7;
}

.ant-picker-calendar .ant-picker-cell-inner {
  padding-left: 10px;
 }

.ant-menu-title-content {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif !important;
  font-size: 1.3em !important;
  color: #37474F !important;
}

.ant-menu-title-content a {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  color: #37474F !important;
  text-decoration: none;
  display: inline-block;
  height: 24px;
}
.ant-menu-item-selected {
  color: #0b290d !important;
}
.ant-menu-item-selected::after {
  border-bottom-color: #0b290d !important;
}
.ant-menu-submenu-selected {
  color: #0b290d !important;
}
.ant-menu-submenu-title {
  font-size: 1em;
  color: #0b290d !important;
}
.ant-menu-submenu-title:hover::after {
  border-bottom-color: #0b290d !important;
}
.ant-menu-submenu-selected::after {
  border-bottom-color: #0b290d !important;
}

.ant-menu-submenu-active:hover {
  border-bottom-color: #0b290d !important;
}
.ant-menu-submenu-active:hover::after {
  border-bottom-color: #0b290d !important;
}

.ant-menu-item:hover {
  border-bottom-color: #0b290d !important;
}

.ant-menu-item:hover::after {
  border-bottom-color: #0b290d !important;
}

.ant-menu-item-active:hover {
  border-bottom-color: #0b290d !important;
}

.ant-menu-horizontal .ant-menu-submenu {
  vertical-align: middle; 
  padding-inline: 10px;
}

.ant-btn-primary {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  background-color: #0b290d !important;
}

.ant-input {
  padding: 8px !important;
  height: 44px !important;
  font-size: 20px;
}

.ant-menu-inline-collapsed {
  display: none;
}

.subTitulo {
  clear:both;
  float:right;
  font-size: 2em;
  color: #ae2800;
  text-align: right;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
  font-family: "Lato", Arial, sans-serif;
}

.subTitulo.sticky {
  text-align: right;
  position: sticky;
  top: 4px;
  right: 0%;
  z-index: 9999;
  background-color: #fff !important;
}

.subTitulo a {
  color: #81C784;
  text-decoration: none;
}

.subTitulo a:hover {
  color: #C8E6C9;
}

.logoTopo {
  margin-top: 30px;
  max-width: 340px;

  @media (max-width: 1250px) {
    margin-top: 20px !important;
    height: 60px;
  }

  @media (max-width: 630px) {
    margin-top: 0px !important;
    height: 60px;
  }
}


.logoTopoRep {
 margin-left: 1px;
 margin-top: 50px;
 max-width: 240px;
 margin-right: 40px;

 @media (max-width: 1250px) {
  margin-top: 30px;
  height: 40px;
}

 @media (max-width: 630px) {
  height: 40px;
  margin-top: 10px;
}
}

.alignTopo a {
  vertical-align: top;
}

.social-icon {
  width: 44px;
  height: 44px;
  padding: 8px;
  font-size: 30px;
  color: #fff;
  transition: transform 0.3s;
  cursor: pointer;
}

.social-icon:hover {
  transform: translateY(-10px);
  color: #ffffff;
}

.logoFooter {
  max-width: 150px;
  padding: 6px;
  margin: 3px;
}

.logoFooter2 {
  max-width: 140px;
}

.div-footer {
  flex-grow: 1;
  vertical-align: top;
  color: #37474F;
  max-width: 33%;
  min-width: 25%;
  margin-top: 24px;

  @media (max-width: 1400px) {
    min-width: 24%;
  }

  @media (max-width: 1250px) {
    min-width: 20%;
  }

}

.div-footer ul {
  padding: 0px !important;
  margin: 0px !important;
}

.div-footer a {
  font-size: 1em !important;
  color: #fff;
}

.div-footer a:hover {
  color: #fff;
}

.div-footer a::after {
  background-color: #fff;
}

.div-qr {
color: #fff;
}

.div-footer-first {
  align-self: top;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
}

.div-footer-first-box {
  align-self: top;
  transition: transform 0.2s ease;
  margin-left: auto;
  margin-right: auto;
  margin: auto auto 10px auto;

  @media (max-width: 600px) {
    width: 100% !important;
  }
}

.div-footer-first-box p a {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  position: relative;
  text-decoration: none;
  text-align: left;
  display: block;
}

.imgfooterfirst {
  max-height: 120px;
  max-width: 220px;
}

.imgfooterfirst2 {
  max-height: 120px;
  max-width: 280px;
}

.div-pesquisa {
  text-align: left;
  margin-top: 30px;
  margin-left: auto;
  margin-right: 5%;
  justify-content: center;

  @media (max-width: 1265px) {
    margin-right: 2%;
    
  }

  @media (max-width: 1065px) {
    margin-top: 20px;

  }
  @media (max-width: 955px) {
    display: none;

  }
}

.card-hover-animation {
  /* Defina as propriedades de animação desejadas */
  margin-bottom: 10px;
  transition: transform 0.2s ease;
}

.card-hover-animation:hover {
  /* Defina as transformações ou estilos desejados ao passar o mouse */
  transform: scale(1.05);
  background-color: #333;
}

.image-gallery-description {
  width: 60% !important;
  text-align: left;
  vertical-align: middle;
  margin-left: 5%;
  padding: 4px !important;
  font-size: 28px;
  color: #fff;
  margin-top: 10px !important;
  background-color: rgba(0, 0, 0, 0) !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  line-height: 1.8em;
  bottom: auto !important;

  @media (max-width: 768px) {
    width: 90% !important;
  }
}

.gal-tit-desc {
  color: #fff;
  padding: 5px 5px 5px 5px;
  border-radius: 5px;
  line-height: 1.3em !important;
  font-size: 2.1em !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  width: 60%;

  @media (max-width: 850px) {
    font-size: 1.5em !important;
    width: 90%;
  }
 
}

.galeria-desc {
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.05em !important;
  position: relative;
  text-decoration: none;
  color: white;
  text-shadow: 0px 2px 6px #000;

  @media (max-width: 768px) {
    font-size: 1.8em !important;
    line-height: 1.1em;
  }
}

.galeria-desc:hover {
  text-decoration: none;
  color: white;
}

.galeria-desc::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: white;
  transition: width 0.3s ease;
}

.galeria-desc:hover::after {
  width: 100%;
}

.image-gallery-bullet:hover {
  background-color: #fff !important;
  height: 10px !important;
  height: 40px;
}

.image-gallery-icon {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.0)) !important;
  color: #B0BEC5 !important;
}

.image-gallery-icon:hover {
  color: #37474F !important;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}


.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: calc(40vh) !important;

  @media (max-width: 768px) {
    height: calc(30vh) !important;
  }
}

.image-gallery-slide-wrapper  {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 30px;
}

.image-gallery-left-nav {
  left: -60px !important;

  @media (max-width: 768px) {
    display: none;
  }
}

.image-gallery-right-nav {
  right: -60px !important;

  @media (max-width: 768px) {
    display: none;
  }
}

.image-gallery-slides {
border-radius: 0 0 50px 50px !important;
}


.thumbnail-gallery {
  border-radius: 50%;
  width: 26px !important;
  height: 26px !important;
  transition: none !important;
  border: 0px !important;
}


.thumbnail-gallery span {
  border-radius: 50%;
  width: 26px !important;
  height: 26px !important;
}
.thumbnail-gallery img {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  border: 2px solid white !important;
}

.thumbnail-gallery.active, .thumbnail-gallery:focus, .thumbnail-gallery:hover {
  border: 0px!important;
  cursor:pointer;
}

.thumbnail-gallery img:hover {
  border: 0px solid white !important;
}

.custom-select span {
  color: #ca1f1f;
  font-size: 16px;
}

.filtro-tit {
  color: #000 !important;
}

.grelha-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* min-height: 160px;*/
  justify-content: center;
}

.filtro-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  text-align: center;

  @media (max-width: 768px) {
    gap: 10px;
  }
}
.filtro-item {
  flex-grow: 1;
  text-align: left;
  min-width: 21%;
  max-width: 30%;

  @media (max-width: 1050px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.text-canais {
  margin-left: 5%;
  margin-right: auto;
}


#btn-topo {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 4rem 4rem;
  border-color: transparent transparent #C8E6C9 transparent;
  background: transparent;
  box-shadow: none;
  color: #DCEDC8;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  font-size: 16px;
  padding-top: 18px;
  cursor: pointer;
  transition: transform 0.3s;
}


#btn-topo.hidden {
  transform: translateY(60px);
  pointer-events: none;
}

.btn-topo-icon {
  position: absolute;
  top: 3.4rem;
  right: 0.7rem;
  height: 1em;
  width: 1em;
  color: #37474F;
}

#btn-voltar {
  display:none;
  position: fixed;
  left: 0;
  top: 0;
  width: 60px;
  height: 57px;
  background-color: #f3f9ec;
  color: #37474F;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  font-size: 24px;
  padding-top: 14px;
  cursor: pointer;
  transition: transform 0.3s;
  z-index: 9999;
}

#btn-voltar.hidden {
  transform: translateX(-60px);
  pointer-events: none;
}

.grelha-item {
  flex-grow: 1;
  border: 1px solid #e6e6e6;
  text-align: left;
  min-width: 19%;
  transition: transform 0.3s ease;
  max-width: 220px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 45%;
}

.grelha-item-recentes {
  position: relative;
  flex-grow: 1;
  border: 1px solid #e6e6e6;
  text-align: left;
  min-width: 15%;
  transition: transform 0.3s ease;
  max-width: 180px;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;

  @media (max-width: 475px) {
    width: 80%;
    max-width: 80%;
    margin-bottom: 10px;
  }
}

.grelha-item-link-recentes {
  position: absolute;
  border-radius: 5px;
  background-color: #666666;
  padding: 8px;
  width: 90%;
  display: block !important;
  text-align: center;
  bottom: 10px;
  color: #fff;
  border: 1px solid #666666;
}


.grelha-item-link-recentes:hover {
  background-color: #fff;
  border: 1px solid #666666;
  color: #666666;
}

.grelha-itemp {
  flex-grow: 1;
  border: 1px solid #e6e6e6;
  text-align: left;
  min-width: 15%;
  transition: transform 0.3s ease;
  max-width: 200px;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 10px;
}

.grelha-itemm {
  flex-grow: 1;
  text-align: left;
  min-width: 48%;
}


.grelha-item-conteudo {
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.grelha-item-conteudo a {
  text-decoration: none;
}

.grelha-item-conteudo-recentes {
  text-decoration: none;
  overflow: hidden;
}

.grelha-item-imagem {
  height: 100px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform 0.3s ease-in-out;
}

.grelha-item-imagem:hover {
  transform: scale(1.1);
}

.grelha-item-ano-titulo {
  margin: 6px 0 6px 10px;
  text-align: left;
  font-size: 0.8em !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.grelha-item-data {
  margin: 6px 0 6px 10px;
  text-align: left;
  font-size: 0.8em !important;
  display: flex;
  align-items: center;
  color: #006638;
  display:block;
}

.margem-esquerda {
  margin-left: 10px;
}

.grelha-item-links {
  margin: 10px;
  text-align: left;
  font-size: 0.7em !important;
}

.grelha-item-data-titulo {
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.6em !important;
  margin-right: 20px;
  width: 300px;
}

.grelha-item-titulo {
  padding: 10px !important;
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.2em !important;
  position: relative;
  text-decoration: none;
  text-align: left;
  max-width: 300px;
  display: block;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #37474F;
  text-decoration: none;
}

.grelha-item-titulo:hover {
  color: #ae2800;
}

.grelha-item-titulo-recentes {
  margin-bottom: 30px;
  padding: 5px !important;
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.2em !important;
  position: relative;
  text-decoration: none;
  text-align: left;
  display: block;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #37474F;
}

.grelha-item-titulo-recentes:hover {
  color: #ae2800;
}


.grelha-item-titulo::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #f5f5f5;
  transition: width 0.3s ease;
}

.grelha-item-titulo:hover::after {
  width: 0%;
}

.grelha-item-titulop {
  margin: 10px !important;
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  font-size: 1.2em !important;
  position: relative;
  text-decoration: none;
  text-align: left;
  max-width: 240px;
  display: block;
}

.grelha-item-titulop:hover {
  cursor: auto;
}

/* Estilos adicionais para quando o mouse estiver sobre os itens */
.grelha-item:hover {
  /* background-color: #f0f3f4; */
  box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  cursor: pointer;
}

.grelha-itemp:hover {
  /* background-color: #f0f3f4; */
  box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  cursor: auto;
}


.agenda-container-wrapper {
  flex-grow: 1;
}

.agenda-titulo {
  color: #37474F;
}

.agenda-mais {
  text-align: center;
}

.agenda-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px;
  height: 500px;
  overflow-y: scroll;
  overflow-x: none;
  padding: 2px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.agenda-item {
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #C8E6C9;
  transition: transform 0.3s ease;
  text-decoration: none;
}

.agenda-item:hover {
  border: 1px solid #ccc;
}

.agenda-item-conteudo {
  display: flex;
  overflow: hidden;
}


.agenda-item-data {
  margin: 20px 10px;
  text-align: center;
  min-width: 40px;
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.5em;
  color:#37474F;
}

.agenda-item-data-ano {
  margin-top: 10px;
  font-size: 0.4em !important;
}

.agenda-item-titulo {
  font-family: "PortuguesaSans-Regular", Arial, sans-serif;
  text-decoration: none;
  text-align: left;
  width: 80% !important;
  font-size: 0.9em !important;
  color:#37474F;
  text-decoration: none;
}

.agenda-item-titulo a {
  text-decoration: none;
}

.agenda-item-titulo::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: width 0.3s ease;
}

.agenda-item-titulo:hover::after {
  width: 100%;
}

.agenda-tipo-evento {
  font-size: 0.7em !important;
}

.agenda-item:hover {
  box-shadow: 0 4px 10px -4px rgba(0,30,43,0.3) !important;
  cursor: pointer;
}


.redes-titulo {
  color: #37474F;
  margin-top: 60px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid #37474F;
}

.redes-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 0px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: none;
  padding: 10px;
}


.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.bolinha-verde {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #82C685;
  margin-left: 30px;
}
.bolinha-vermelha {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #F44336;
  margin-left: 30px;
}
.bolinha-laranja{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #FFA726;
  margin-left: 30px
}
.bolinha-azul{
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1976D2;
}

.ant-steps-item-title {
  color: black !important;
  font-size: 16px !important;
}
.ant-steps-item-description {
  color: black !important;
  font-size: 16px !important;
  font-weight: 700;
}
.ant-steps-item-content {
  width: 200px !important;
}

.div-oculta {
  display: none;
}

.texto-normal {
  font-size: 1em !important;
}

.texto-verde {
  color: #387c3b !important;
}
.texto-vermelho {
  color: #ae2800 !important;
}
.texto-laranja{
  color: #b25a00 !important;
}
.texto-azul{
  color: #0e6f77 !important;
}
.texto-cinza{
  color: #37474F !important;
}
.texto-branco{
  color: #ffffff !important;
}

.texto-data {
  font-size: 0.9em;
}

.linha {
  width: 100%;
  border: none;
  border-bottom: 1px solid #cccccc;
}

.calendar-link {
  font-size: 14px !important;
}

.local-icon-text-container {
  display: flex;
  align-items:center;
}

.local-icon-container {
  margin-right: 8px; /* Espaçamento entre o ícone e o texto */
}

.local-text-container {
  flex: 1; /* Ocupa o espaço restante disponível */
}

.calendariobox {
margin-top: 20px;
height: 200px;
background-size: cover;
background-color: #f2f2f2;
}

.calendario-contactos {
  background-color: #fff;
  padding: 10px;
  color: #37474F;
}

.calendario-contactos a {
  color: #37474F;
}

.fundo-verde {
  background-color: #C8E6C9;
}

.fundo-titulo {
  padding: 6px;
  background: linear-gradient(90deg,#fff 0%, #f8f8f8 30%, #f8f8f8 50%, #f8f8f8 70%, #fff 100%);
}

/* Classe para mostrar um elemento */
.show {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

/* Classe para ocultar um elemento */
.hide {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
}

.mouse {
  cursor: pointer;
}
/* Estilo base para o modal (inicialmente oculto) */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

/* Estilo para o conteúdo do modal */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: 9;
}

/* Estilo para o botão "Fechar" do modal */
.close {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 20px;
  cursor: pointer;
}

/* Estilo para a imagem ampliada */
.modal-image {
  max-width: 90vw;
  max-height: 90vh;
}

/* Quando o modal estiver visível, altere a regra display */
.modal.open {
  display: block;
}

.ant-picker-calendar .ant-picker-cell .ant-picker-cell-inner {
  z-index:0;
  width: 100% !important;
}

.table-container {
  max-height: 100vh;
  overflow: auto;
}

.table-documentos {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 550px;
  
  @media (max-width: 860px) {
    width: 90%;
  }
}

.table-documentos tr td {
  font-family: "Lato", Arial, sans-serif !important;
}

.table-documentos-ano {
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 550px;
  
  @media (max-width: 860px) {
    width: 90%;
  }
}

.table-documentos-ano tr td {
  font-family: "Lato", Arial, sans-serif !important;
}

.table-documentos thead th, tbody td  {
  border: none;
  text-align: left;
}

.table-excel {
  border-collapse: collapse;
  width: 100%;
  background-color: #f9f9f9;
}

.table-excel td {
  padding: 8px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  min-width: 100px;
  text-align: left;
  line-height: 1.5em !important;
}

.table-excel th {
  background-color: #f9f9f9;
  padding: 4px;
  font-size: 0em;
}

.table-excel tr:nth-child(even){
  background-color: #fff;
}

.table-excel tr:hover {
  background-color: #f1f1f1;
}

.table-excel tr:first-child {
  position:sticky;
  background-color: #37474F;
  color: white;
  z-index: 1;
  top: 0;
  height: 40px;
}
input[type="text"] {
  background-color: #fff;
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
}

.shortlinkinput {
  background-color: #37474F !important;
  width: 100%;
  padding: 6px;
  border: 0px solid #37474F !important;
  color: #fff !important
}

.filtro-seletor {
  max-width: 300px;
  padding: 6px;
  margin: 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

.selectors {
  background-color: #e6e6e6;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
}

.ant-breadcrumb a {
color: rgba(0, 0, 0, 0.85) !important;
height: 24px !important;
}

.div-oculto {
  display: none;
}


.paragrafoTexto {
width: 700px;
margin-left: auto;
margin-right: auto;

@media (max-width: 800px) {
  width: 90%;
}
}

.search-icon-input {
  background: url('magnifying-glass.png') no-repeat 8px center;
  padding-left: 30px !important;
}

.texto-rodape {
  font-size: 1em !important;
  line-height: 1.3em !important;
  padding-top: 8px;;
  list-style-type: none;
}

.texto-rodape a {
  text-decoration: none;
}

.texto-rodape a:hover {
  text-decoration: underline;
}


.div-docs {
  @media (max-width: 768px) {
    flex-basis: 100%;
  }
}

.divArtigoAgenda {
  margin: 6px;
  background-color: #f1f1f1;
  width: 250px;
  float: left;

  @media (max-width: 768px) {
    width: 90%;
    float: none;
  }

}

.MenuAcess a {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  font-size: 1.2em;
  color: #000
}

.MenuAcess a:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  left: auto;
}

.MenuAcess a:nth-child(1) {
  top: 0;
}

.MenuAcess a:nth-child(2) {
  top: 24px;
}

.MenuAcess a:nth-child(3) {
  top: 48px;
}

.MenuAcess a:nth-child(4) {
  top: 72px;
}

table caption {
  display: none;
}

.carousel-root {
  height: calc(40vh) !important;
}
.slide {
  height: calc(40vh) !important;
  text-align: left !important;
}

.caroussel-gallery {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  border-radius: 0 0 50px 50px !important;
  margin-left: 40px;
  padding: 20px 20px 20px 40px;
}

.slider {
  margin: auto !important;
}

.control-arrow {
  background-color: #000;
  background: transparent !important;
  padding: 5px 5px 7px 5px !important;
}

.carousel .control-prev.control-arrow:before {
border-right: 8px solid #000 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.ant-badge-status-text {
  background-color: #fff;
}