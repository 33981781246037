@font-face {
  font-family: 'PortuguesaSans-Regular';
  src: url("./fonts/PortuguesaSans-Regular.otf"); /* IE9 Compat Modes */
  src: url("./fonts/PortuguesaSans-Regular.otf?#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("./fonts/PortuguesaSans-Regular.woff2") format('woff2'), /* Modern Browsers */
       url("./fonts/PortuguesaSans-Regular.woff") format('woff'), /* Modern Browsers */
       url("./fonts/PortuguesaSans-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}


@font-face {
  font-family: 'PortuguesaSerif-Light';
  src: url("./fonts/PortuguesaSerif-Light.otf"); /* IE9 Compat Modes */
  src: url("./fonts/PortuguesaSerif-Light.otf?#iefix") format('embedded-opentype'), /* IE6-IE8 */
       url("./fonts/PortuguesaSerif-Light.woff2") format('woff2'), /* Modern Browsers */
       url("./fonts/PortuguesaSerif-Light.woff") format('woff'), /* Modern Browsers */
       url("./fonts/PortuguesaSerif-Light.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
  font-family: 'Lato';
  src: url(./fonts/Lato-Regular.eot); /* IE9 Compat Modes */
  src: url(./fonts/Lato-Regular.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/Lato-Regular.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/Lato-Regular.woff) format('woff'), /* Modern Browsers */
       url(./fonts/Lato-Regular.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Semibold */@font-face {
  font-family: 'LatoSemibold';
  src: url(./fonts/Lato-Semibold.eot); /* IE9 Compat Modes */
  src: url(./fonts/Lato-Semibold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
       url(./fonts/Lato-Semibold.woff2) format('woff2'), /* Modern Browsers */
       url(./fonts/Lato-Semibold.woff) format('woff'), /* Modern Browsers */
       url(./fonts/Lato-Semibold.ttf) format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}